<template>
    <div>
        <div class="d-flex justify-center items-center">
            <b-form-input
                type="date"
                locale="en"
                v-model="startDate">
            </b-form-input>
            <svg
                class="w-1 h-1 mx-2"
                height="40px"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
            </svg>
            <b-form-input
                type="date"
                v-model="endDate">
            </b-form-input>
        </div>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>


export default {
    name: 'DateBetween',
    props: {
        value: {
            default: null,
        },
        validateError: {
            default: null,
        }
    },
    computed: {
        startDate: {
            get(){
                if (!this.value) return null;

                let arr = this.value.split(',')
                return arr[0]
            },
            set(value){
                this.$emit('input',value)
            }
        },
        endDate: {
            get(){
                if (!this.value) return null;

                let arr = this.value.split(',')

                return arr[1]
            },
            set(value){
                let arr = this.value.split(',')
                this.$emit('input',arr[0]+','+value)
            }
        }
    },
    methods: {

    }
}
</script>
